




























































































































.question {
  font-size: 16px;
  font-weight: bold;
  table {
    max-width: 100%!important;
    min-width: 0!important;
    display: table;
  }
  img {
    max-width: 100%!important;
  }
}
.answers {
    font-size: 14px;
}
.correct-answer {
    background-color: #00f889;
}
